import React, { Component } from 'react';
import { getMarkers, downloadMarkers } from '../../services/stratigraphicService';
import { FaFileDownload } from "react-icons/fa";
import { EventNames } from '../../utils/enums/EventNames';



interface MarkersTreeViewState {
    markers: any,
}
  
interface MarkersTreeViewProps {

}

/**
 * Event to dispatch refresh MarkersTreeView in sidebar.
 */
export function refreshMarkersTreeViewDataTab(){
    const customEvent = new CustomEvent(EventNames.refreshMarkersTreeViewEvent, { detail: {} 
    });
    document.dispatchEvent(customEvent);
}

export default class MarkersTreeView extends Component<MarkersTreeViewProps, MarkersTreeViewState> {
    
    refreshSideBarEvent:string = EventNames.refreshMarkersTreeViewEvent;

    constructor(props: any) {
        super(props);

        this.state = {
            markers:{}
        }

        this.loadMarkers();
    }

    componentDidMount() {  
        document.addEventListener(this.refreshSideBarEvent, this.refreshEvent);
    }
    
    componentWillUnmount() {
        document.removeEventListener(this.refreshSideBarEvent, this.refreshEvent);
    }

    refreshEvent = (event:any) =>{
        if (event.type !== this.refreshSideBarEvent){
            return;
        }
        this.loadMarkers();
    }

    /**
     * Load correlations tree.
     */
    loadMarkers = () =>{
        const markersData = getMarkers();
        let arrMarkers: string[] = []
        markersData.then((response)=>{
            let markers:any[] = response["zones"];
            Object.keys(markers).map((marker:any, idx:number)=>{
                arrMarkers.push(markers[idx]["name"])
            })                            
            this.setState({markers:arrMarkers});
        });
    }

    downloadMarkers = () => {
        const d = new Date();
        const currentDate = new Date(d.getTime() - (d.getTimezoneOffset() * 60000)).toISOString().split(".")[0].replace(/[T:]/g, '').replace(/-/g,"");;
        const params:any = {"filename" : `markers-${currentDate}.txt`};
        downloadMarkers(params);
    }



    render(): React.ReactNode {        
        return(
            <>
                <div className='sidebar-section styled-scrollbars'>                                      
                    <details>
                    <summary className='title'>Markers <i className="arrow down"></i></summary>
                        <div>
                            <ul>
                                {
                                    Object.keys(this.state.markers).map((marker:any, idx:number)=>{
                                        return (<li>{ this.state.markers[marker] }</li>)
                                    })               
                                }
                            </ul>
                        </div>
                    </details>                       
                </div>                  
            </>
        )
    }
}
import React, { Component } from 'react';

import { getMultiwells } from '../../services/correlationService';
import { setCorrelationViewTab } from '../Tabs/ViewTabsComponent';
import { changeCorrelationData } from '../Tabs/CorrelationsViewTabComponent/CorrelationsViewTabComponent';
import { EventNames } from '../../utils/enums/EventNames';

interface WellCorrelations{
    name: string,
    id: number,
    pairwiseId: number,
    pairwiseName: string,
}

interface CorrelationsTreeViewState {
    correlations: WellCorrelations[],
}
  
interface CorrelationsTreeViewProps {

}

export function refreshCorrelationTreeViewDataTab(){
    const customEvent = new CustomEvent(EventNames.refreshCorrelationTreeViewEvent, { detail: {} 
    });
    document.dispatchEvent(customEvent);
}

export default class CorrelationsTreeView extends Component<CorrelationsTreeViewProps, CorrelationsTreeViewState> {

    refreshEvent: string = EventNames.refreshCorrelationTreeViewEvent;

    constructor(props: any) {
        super(props);

        this.state = {
            correlations:[]
        }

        this.loadCorrelations();
    }

    componentDidMount() {  
        document.addEventListener(this.refreshEvent, this.loadCorrelations);
    }
    
    componentWillUnmount() {
        document.removeEventListener(this.refreshEvent, this.loadCorrelations);
    }

    /**
     * Load correlations tree.
     */
    loadCorrelations = () =>{
        const multiwellData = getMultiwells();
        multiwellData.then((response)=>{
            let correlationsData:WellCorrelations[] = [];
            Object.keys(response).forEach((corretion,idx)=>{
                const currentCorrelation = response[corretion];
                correlationsData.push({
                    id:currentCorrelation.id,
                    name: currentCorrelation.model_name,
                    pairwiseName: corretion,
                    pairwiseId: currentCorrelation.pairwise_id,
                });
            });
            this.setState({correlations:correlationsData});
        });
    }

    /**
     * Correlation click event.
     * @param treeIdx Correlation index in tree.
     */
    correlationClick = (treeIdx:number) =>{
        const selectedCorrelation = this.state.correlations[treeIdx];
        setCorrelationViewTab();
        changeCorrelationData(
            selectedCorrelation.pairwiseId,
            selectedCorrelation.pairwiseName,
            selectedCorrelation.id,
            selectedCorrelation.name,
        );        
    }

    render(): React.ReactNode {        
        return(
            <>
                <div className='sidebar-section styled-scrollbars'>                                      
                    <details>
                    <summary className='title'>Automated Correlations <i className="arrow down"></i></summary>
                        <div>
                            <ul>
                                {
                                    this.state.correlations?.map((correlation, idx)=>{
                                        if(correlation.name){
                                            return (<li onClick={(event)=>{ this.correlationClick(idx); }}>{correlation.name}</li>);
                                        }else{
                                            return(<li>No correlations yet</li>)
                                        }
                                    })                                    
                                }
                                {
                                    this.state.correlations.length <= 0 && 
                                    <li>No correlations yet</li>
                                }
                            </ul>
                        </div>
                    </details>
                       
                </div> 
            </>
        )
    }
}
import ToastStyles from "../../utils/models/ToastStyles";
import { toast } from 'react-toastify';
//https://fkhadra.github.io/react-toastify/introduction

export default class ToastHelper {
    private static toaster = (message: string, toastStyle: ToastStyles, originalError: any=null) => {
        return new Promise((resolve, reject) => {
            if(!originalError){
                toast(message, {style: toastStyle, onOpen: () => resolve(message)}); 
            } else {
                toast(message, {style: toastStyle, onOpen: () => resolve(originalError)}); 
            }                                     
        });          
    }

    public static success = (message: string) => {
        return ToastHelper.toaster(message, ToastStyles.success);
    }

    public static warning = (message: string) => {
        return ToastHelper.toaster(message, ToastStyles.warning);
    }

    public static error = (message: string, originalError?: any) => {
        return ToastHelper.toaster(message, ToastStyles.error, originalError);
    }

    public static info = (message: string) => {
        return ToastHelper.toaster(message, ToastStyles.info);
    }
}
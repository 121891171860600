import isEqual from 'lodash.isequal';
import React, { Component } from 'react';

interface DlisMetaparametersViwerProps{
    dlisMetadata:any;
    onChange:any;
}

interface CheckedFrame{
    wellName:string;
    fileName:string;
    frameName:string;
}

interface DlisMetaparametersViwerState {
    selectedFrames:CheckedFrame[];
}

export default class DlisMetaparametersViwer extends Component<DlisMetaparametersViwerProps, DlisMetaparametersViwerState> {

    constructor(props:DlisMetaparametersViwerProps){
        super(props);
        this.state = {
            selectedFrames:[]
        }

    }

    componentDidMount(): void {
        let frames:CheckedFrame[] = [];
        Object.keys(this.props.dlisMetadata).forEach((fileName:string)=>{
            return Object.keys(this.props.dlisMetadata[fileName]).forEach((wellName)=>{
                return Object.keys(this.props.dlisMetadata[fileName][wellName]).forEach((logicalFile)=>{
                    return this.props.dlisMetadata[fileName][wellName][logicalFile].forEach((frame:any)=>{
                        return frames.push({wellName:wellName,frameName:`${wellName},${logicalFile},${frame.name}`, fileName:fileName})
                    })
                })
            })
        })
        this.setState({selectedFrames:frames},()=>{this.props.onChange(frames)});
    }

    componentDidUpdate(prevProps: Readonly<DlisMetaparametersViwerProps>, prevState: Readonly<DlisMetaparametersViwerState>, snapshot?: any): void {
        if(!isEqual(this.props,prevProps)){
            let frames:CheckedFrame[] = [];
            Object.keys(this.props.dlisMetadata).forEach((fileName:string)=>{
                return Object.keys(this.props.dlisMetadata[fileName]).forEach((wellName)=>{
                    return Object.keys(this.props.dlisMetadata[fileName][wellName]).forEach((logicalFile)=>{
                        return this.props.dlisMetadata[fileName][wellName][logicalFile].forEach((frame:any)=>{
                            return frames.push({wellName:wellName,frameName:`${wellName},${logicalFile},${frame.name}`, fileName:fileName})
                        })
                    })
                })
            })
            this.setState({selectedFrames:frames},()=>{this.props.onChange(frames)});
        }
    }

    setFrame =(fileName:string, wellName:string, frameName:string, checked:boolean)=>{
        let frames = this.state.selectedFrames;
        if(checked){
            frames.push({wellName:wellName,frameName:frameName,fileName:fileName});
        }
        else{
            frames = frames.filter((frame)=>frame.frameName != frameName);
        }
        this.setState({selectedFrames:frames},()=>{this.props.onChange(frames)});
    }

    selectAll(checked:boolean){
        let frames:CheckedFrame[] = [];
        if(checked){
            Object.keys(this.props.dlisMetadata).forEach((fileName:string)=>{
                return Object.keys(this.props.dlisMetadata[fileName]).forEach((wellName)=>{
                    return Object.keys(this.props.dlisMetadata[fileName][wellName]).forEach((logicalFile)=>{
                        return this.props.dlisMetadata[fileName][wellName][logicalFile].forEach((frame:any)=>{
                            return frames.push({wellName:wellName,frameName:`${wellName},${logicalFile},${frame.name}`, fileName:fileName})
                        })
                    })
                })
            })
        }
        else{
            frames = [];
        }
        this.setState({selectedFrames:frames},()=>{this.props.onChange(frames)});
    }

    detectedFrameChecked(wellName:string, frameName:string){
        let frames = this.state.selectedFrames;
        const size = frames.filter((frame)=>{return frame.frameName == frameName && frame.wellName == wellName}).length;
        return size > 0;
    }

    render(): React.ReactNode {
        return(
            <>
                <table style={{backgroundColor:"white", borderRadius:"20px", textAlign:"center"}}>
                    <thead>
                        <tr>
                            <th><input type="checkbox" onChange={(event)=>{this.selectAll(event.target.checked)}} /></th>
                            <th>File Name</th>
                            <th>Name in file</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            Object.keys(this.props.dlisMetadata).map((fileName:string)=>{
                                return Object.keys(this.props.dlisMetadata[fileName]).map((wellName)=>{
                                    return Object.keys(this.props.dlisMetadata[fileName][wellName]).map((logicalFile)=>{
                                        return this.props.dlisMetadata[fileName][wellName][logicalFile].map((frame:any)=>{
                                            const frameName = `${wellName},${logicalFile},${frame.name}`
                                            return (
                                                <tr>
                                                    <td><input type="checkbox" checked={this.detectedFrameChecked(wellName,frameName)} onChange={(event)=>{ this.setFrame(fileName,wellName,frameName,event.target.checked) }} /></td>
                                                    <td>{fileName}</td>
                                                    <td>{frameName}</td>
                                                </tr>
                                            )
                                        })
                                    })
                                })
                            })
                        }
                    </tbody>
                </table>
            </>
        )
    }

}
//External packages
import React, { Component } from 'react';

// Internal packages
import { StandartColors } from '../../../../utils/enums/StandartColors';
import WellsCorrelationsTab from './WellsCorrelationTab/WellsCorrelationTab';
import GeologicalSettingsCorrelationTab from './GeologicalSettingsCorrelationTab/GeologicalSettingsCorrelationTab';
import { CorrelationTabEvents } from '../../../../utils/enums/CorrelationsTab';
import PropertiesCorrelationsTab from './PropertyCorrelationTab/PropertyCorrelationTab';
import OptionsCorrelationsTab from './OptionsCorrelationTab/OptionsCorrelationTab';
import { wellBoundaries, wellMetadata } from '../../../../services/wellService';
import { processPairwise, processMultiwell, saveMultiwell, savePaiwise, getCorrelationPairs } from '../../../../services/correlationService';
import { setProgress, ProgressBar } from '../../../ProgressBar/ProgressBar';
import { refreshDataTab } from '../../DataTabComponent/DataTabComponent';

import "./AutomatedCorrelationComponent.css";
import ToastHelper from '../../../../utils/helpers/ToastHelper';
import { getMarkersData } from '../../../../services/stratigraphicService';
import { setCorrelationViewTab } from '../../ViewTabsComponent';
import { changeCorrelationData } from '../../CorrelationsViewTabComponent/CorrelationsViewTabComponent';

const CONTEXT = 'AutomatedCorrelationComponent'

interface AutomatedCorrelationComponentState {
  activeTab: string,
  correlationName: string,
  wellSet: string,
  allWells: boolean,
  propertiesSet: string,
  allProperties:boolean,
  wells: string[],
  properties: string[],
  logsOptions: any,
  refresh: boolean,
  correlationRadius: number,
  correlationOffset: number,
  correlationResolution: number,
  correlationDepth: string,
  boundariesTable:any,
  running:boolean,
  n_cores:number,
  wellsPairsDistance:any,
  boundariesData:any,
  wellsMarkers:string[],
  wellsProperties:any,
}

enum TabNames{
  wells = "Wells",
  properties = "Properties",
  geologicalSettings = "Geological Settings",
  correlationOptions = "Correlation Options",
  hostsSettings = "Hosts Settings"
}

const tabsOrder: string[] = [
  TabNames.wells,
  TabNames.properties,
  TabNames.geologicalSettings,
  TabNames.correlationOptions,
]

class AutomatedCorrelationComponent extends Component<{}, AutomatedCorrelationComponentState> {

  wellsProperties: {[index: string]:string[]}

  wellUpdateEvent: string = CorrelationTabEvents.wellUpdate;
  propertyUpdateEvent: string = CorrelationTabEvents.propertyUpdate;
  optionUpdateEvent: string = CorrelationTabEvents.correlationOptionsUpdate;
  geologicalSettingsUpdateEvent: string = CorrelationTabEvents.geologicalSettingsUpdate;
  previousTabEvent: string = CorrelationTabEvents.previousTabEvent;
  nextTabEvent: string = CorrelationTabEvents.nextTabEvent;

  constructor(props: any) {
    super(props);
    this.wellsProperties = {}
    this.state = {
      activeTab: TabNames.wells,
      correlationName:"",
      wells:[],
      allWells:true,
      properties:[],
      allProperties:true,
      wellSet:"",
      propertiesSet:"",
      logsOptions:{},
      refresh:false,
      correlationRadius: 15,
      correlationOffset: 200,
      correlationResolution: 1,
      correlationDepth: "MD",
      boundariesTable:[],
      running:false,
      n_cores: 2,
      wellsPairsDistance:[],
      boundariesData:{},
      wellsMarkers:[],
      wellsProperties:{}
    }
  }

  handleTabClick = (tabId: any) => {
    this.setState({ activeTab: tabId});
  };

  updateBoundariesDataset = () =>{
    wellBoundaries().then((response)=>{
        this.setState({boundariesData:response})
    })
  }

  getWellMarkers(){
    getMarkersData().then(response=>{
        const markers:string[] = response.markers_names;
        this.setState({wellsMarkers:markers});
    })
  }

  componentDidMount() {
    this.getWellMarkers();
    this.getWellsPairsDistance();
    this.updateBoundariesDataset();
    document.addEventListener(this.wellUpdateEvent, this.setWells);
    document.addEventListener(this.propertyUpdateEvent, this.setProperties);
    document.addEventListener(this.optionUpdateEvent, this.setPropertiesOptions);
    document.addEventListener(this.geologicalSettingsUpdateEvent, this.setGeologicalSettings);
    document.addEventListener(this.previousTabEvent, this.previoutTab);
    document.addEventListener(this.nextTabEvent, this.nextTab);
  }

  componentWillUnmount() {
    document.removeEventListener(this.wellUpdateEvent, this.setWells);
    document.removeEventListener(this.propertyUpdateEvent, this.setProperties);
    document.removeEventListener(this.optionUpdateEvent, this.setPropertiesOptions);
    document.removeEventListener(this.geologicalSettingsUpdateEvent, this.setGeologicalSettings);
    document.removeEventListener(this.previousTabEvent, this.previoutTab);
    document.removeEventListener(this.nextTabEvent, this.nextTab);
  }

  getWellsPairsDistance =()=>{
    const well_metadata = wellMetadata();
    well_metadata.then((metadata)=>{
        const wells_names = Object.keys(metadata);
        if(wells_names.length > 0){
          // wellsProperties: {[index: string]:string[]} = {}
          wells_names.forEach((wellName:string)=>{
              if(metadata[wellName].curves.versions.length > 0 && metadata[wellName].curves.versions[0].curves_info != undefined){
                this.wellsProperties[wellName] = Object.keys(metadata[wellName].curves.versions[0].curves_info)
              }
            })
          getCorrelationPairs({wellnames:wells_names}).then(response=>{
            this.setState({wellsPairsDistance:response})
          });
        }
    });
  }

  mountPairwiseDataset = async () =>{
    const metadata = wellMetadata();
    const norms = Object.fromEntries(this.state.properties.map( (log_name:any) => [log_name,this.state.logsOptions[log_name].norm]));
    const samplingMethods = Object.fromEntries(this.state.properties.map( (log_name:any) => [log_name,this.state.logsOptions[log_name].sampling] ));
    return {
      wellnames: await this.mountWellsDataset(metadata),
      logs_names: Array.from(new Set(this.state.properties)),
      max_lags:this.state.correlationOffset,
      norm:norms,
      boundaries: await this.mountBoundaryDataset(metadata),
      show_markers:true,
      correlation_resolution: this.state.correlationResolution,
      radius_correlation: this.state.correlationRadius,
      correlation_depth: this.state.correlationDepth,
      sampling_method: samplingMethods,
      n_cores:this.state.n_cores,
    }
  }

  mountWellsDataset = async (well_metadata:any) =>{
    let wells:any = []
    await well_metadata.then((response:any)=>{
      this.state.wells.forEach((wellName:any,idx)=>{
        const logs = ["MD"].concat(this.state.properties);
        // const logs = this.state.properties;
        const current_well = {
          wellname:wellName,
          logs: logs,
          version: response[wellName].curves.versions[0].id
        }
        wells.push(current_well)
        // boundariesDataset[wellName].step = response[wellName].well_info.STEP[2];
      });
    });
    return wells;
  }

  mountBoundaryDataset = async (well_metadata:any) =>{
    let boundariesDataset: {[index: string]:any} = {}
    await well_metadata.then((response:any)=>{
      this.state.boundariesTable.forEach((row:any,idx:any) => {
        Object.keys(row.wells).forEach((well_name:string,well_idx:any) => {
          const currentWellName = `${well_name} - Resolution ${response[well_name].curves.versions[0].name}`
            if(!boundariesDataset[currentWellName]){
              boundariesDataset[currentWellName] = {
                markers: [] // TODO: create case to marker constraints!
              }
            }
            switch(row.name){
              case "Correlation Start":
                boundariesDataset[currentWellName].start = parseInt(row.wells[well_name]);
                break;
              case "Datum":
                if(row.wells[well_name] != '-'){
                  boundariesDataset[currentWellName].base = parseInt(row.wells[well_name]);
                }
                else{
                  boundariesDataset[currentWellName].base = 0;
                }
                break;
              case "Correlation Stop":
                boundariesDataset[currentWellName].stop = parseInt(row.wells[well_name]);
                break;
              case "Marker Constraints":
                // TODO: create case to marker constraints!
                if(row.wells[well_name] != '-' && row.marker.trim() != ""){
                  boundariesDataset[currentWellName].markers.push(row.marker)
                }
                break;
            }
            Object.keys(response).forEach((wellName:any,idx)=>{
              boundariesDataset[currentWellName].step = parseFloat(response[wellName].well_info.STEP[2]);
            });
          });
      });
    });
    return await boundariesDataset;
  }

  mountMultiwellDataset = (pairwise_name:string) =>{
    const weights = Object.fromEntries(this.state.properties.map( (log_name:any) => [log_name,this.state.logsOptions[log_name].logWeights] ))
    return {
      iterations: 1,
      log_weight: weights,
      pairwise_model: pairwise_name,
      force_weights: false
    }
  }

  setGeologicalSettings = (event:any) => {
    if (event.type !== this.geologicalSettingsUpdateEvent){
      return;
    }
    let dataset = event.detail;
    this.setState({
      correlationRadius:dataset.correlationRadius,
      correlationDepth:dataset.correlationDepth,
      correlationResolution:dataset.correlationResolution,
      correlationOffset:dataset.correlationOffset,
      boundariesTable:dataset.boundariesTable,
    });
  }

  setProperties = (event:any) =>{
    if (event.type !== this.propertyUpdateEvent){
      return;
    }
    let dataset = event.detail;
    let currentWellsProperties: {[index: string]:string[]} = {};
    Object.keys(this.wellsProperties).forEach((wellName)=>{
      currentWellsProperties[wellName] = dataset.properties.filter((propertyName:string)=>this.wellsProperties[wellName].includes(propertyName))
    });

    this.setState({
      properties:dataset.properties,
      propertiesSet:dataset.currentPropertySet,
      wellsProperties:currentWellsProperties,
      refresh:true
    },
      ()=>{setTimeout(()=> this.setState({refresh:false}),200)}
    )
  }

  setPropertiesOptions = (event:any) =>{
    if (event.type !== this.optionUpdateEvent){
      return;
    }
    let dataset = event.detail;
    this.setState({logsOptions:dataset.logsOptions, n_cores:dataset.numberOfCores});
  }

  setWells = (event:any) => {
    if (event.type !== this.wellUpdateEvent){
      return;
    }
    let dataset = event.detail;

    this.setState({wells:dataset.wellNames,wellSet:dataset.currentWellSet, refresh:true},()=>{setTimeout(()=> this.setState({refresh:false}),200)})
  }

  changeCorrelationName = (event:any) =>{
    this.setState({correlationName:event.target.value})
  }

  processCorrelation = async () =>{
    if( this.state.correlationName.trim() == "" || !this.state.correlationName ){
      ToastHelper.error("Error, Name the correlation field cannot be empty." , ()=>{
        console.error("Error, the correlation name cannot be empty.");
        return;
      });
    }
    else{
      this.setState({running:true})
      setProgress(0,CONTEXT,"Starting correlation");
      const pairwiseDataset =  await this.mountPairwiseDataset();
      setProgress(5,CONTEXT,"Running Pairwise");
      const pairwise = processPairwise(pairwiseDataset).then((response_pw)=>{
        if(response_pw.message != undefined){
          ToastHelper.error(`Error: ${response_pw.message}` , ()=>{
            console.error(`Error: ${response_pw.message}`);
            return;
          });
          setProgress(100,CONTEXT,`Saving ${this.state.correlationName}`);
          this.setState({running:false, activeTab: TabNames.wells})
        }
        else{
          setProgress(75,CONTEXT,"Running Multiwell");
          const multiwellDataset = this.mountMultiwellDataset(response_pw.temporary_name);
          processMultiwell(multiwellDataset).then((response_mw)=>{
            setProgress(99,CONTEXT,`Saving ${this.state.correlationName}`);
            saveMultiwell({
                pairwise_id: response_pw.id,
                model_name:this.state.correlationName,
                dataset:multiwellDataset
              }).then((response)=>{
                savePaiwise({
                  id: response_pw.id,
                  name: this.state.correlationName
                }).then((response)=>{
                  setProgress(100,CONTEXT,"Correlations completed!");
                  this.setState({running:false})
                  refreshDataTab();
                  setCorrelationViewTab()
                  changeCorrelationData(response_pw.id,this.state.correlationName, response_mw.id, this.state.correlationName)
                })
              }).catch((error)=>{
                console.log("Error in save multiwell", error);
                this.setState({running:false})
              });
          }).catch((error)=>{
            console.log("Error in process Multiwell", error);
            this.setState({running:false})
          });

        }
      }).catch((error)=>{
        console.log("Error in process Pariwise", error);
        this.setState({running:false})
      });
    }
  }

  nextTab = (event:any) =>{
    if (event.type !== this.nextTabEvent){
      return;
    }
    const currentTabIdx = tabsOrder.indexOf(this.state.activeTab);
    let nextIdx = currentTabIdx + 1;
    if(nextIdx > tabsOrder.length -1){
      nextIdx = 0;
    }
    this.setTab(tabsOrder[nextIdx]);
  }

  previoutTab = (event:any) =>{
    if (event.type !== this.previousTabEvent){
      return;
    }

    const currentTabIdx = tabsOrder.indexOf(this.state.activeTab);
    let nextIdx = currentTabIdx - 1;
    if(nextIdx == -1){
      nextIdx = tabsOrder.length - 1;
    }
    this.setTab(tabsOrder[nextIdx]);
  }

  setTab = (tabName:string) => {
    this.setState({activeTab:tabName});
  }

  tabsView = () =>{
    return (
      <section id="automatedCorrelation">        
        <div className={'divRunCorrelation'}>
          <label>
            Run Correlation:
          </label>
          <input type='text' placeholder='Correlation name' onChange={(event:any) => {this.changeCorrelationName(event)}} value={this.state.correlationName} />
          <input type="button" disabled={this.state.correlationName.trim()==""} value="Run" className='run-button align-content-end' onClick={()=>{this.processCorrelation()}} />        
        </div>
        <div style={{textAlign: "center"}}>          
          {this.tabHeader()}
        </div>
          <div className="borderedDiv">
            <div className="tab-content styled-scrollbars" id="nav-tab-automated-correlation-component">
              <div
                className={`tab-pane fade show ${this.state.activeTab === TabNames.wells ? 'active' : ''}`}
                id="Correlation-Wells-View"
                role="tabpanel"                
              >
                <WellsCorrelationsTab wellsNames={this.state.wells} propertiesSelected={this.state.properties} />
              </div>
              <div
                className={`tab-pane fade show ${this.state.activeTab === TabNames.properties ? 'active' : ''}`}
                id="Correlation-Properties-View"
                role="tabpanel"                
              >
                <PropertiesCorrelationsTab wellsSelected={this.state.wells} />
              </div>
              <div
                className={`tab-pane fade show ${this.state.activeTab === TabNames.geologicalSettings ? 'active' : ''}`}
                id="Correlation-Geological-View"
                role="tabpanel"                
              >
                { !this.state.refresh && (
                  <GeologicalSettingsCorrelationTab 
                    correlationRadius={this.state.correlationRadius}
                    correlationOffset={this.state.correlationOffset}
                    correlationResolution={this.state.correlationResolution}
                    correlationDepth={this.state.correlationDepth}
                    wells={this.state.wells}
                    tableRows={this.state.boundariesTable}
                    wellsPairsDistance={this.state.wellsPairsDistance}
                    boundariesDataset={this.state.boundariesData}
                    wellsMarkers={this.state.wellsMarkers}
                    wellsProperties={this.state.wellsProperties}
                />
                )}
              </div>
              <div
                className={`tab-pane fade show ${this.state.activeTab === TabNames.correlationOptions ? 'active' : ''}`}
                id="Correlation-Options-View"
                role="tabpanel"
              >
                {!this.state.refresh && <OptionsCorrelationsTab logsNames={this.state.properties} logsOptions={this.state.logsOptions}/>}
              </div>
            </div>            
          </div>
          {this.tabsNavButton()}
      </section>
    )
  }

  tabHeader = () =>{
    return (
      <div className="process-nav">{tabsOrder.map((currentTab,idx)=>{
        return (
          <div className={`col k-tab ${currentTab==this.state.activeTab?"activeTab":""}`} onClick={()=>{this.setTab(currentTab)}}>
            {currentTab}
          </div>
        )
      })}
      </div>
    )
  }

  tabsNavButton = () =>{
    let activeTabIdx = tabsOrder.indexOf(this.state.activeTab);
    return (
        <div className="tabsNavButton">
            { tabsOrder.indexOf(this.state.activeTab) != 0 && <button className='nav-btn' onClick={()=>{this.setTab(tabsOrder[activeTabIdx - 1])}}>
                back
            </button>}
            { tabsOrder.indexOf(this.state.activeTab) < tabsOrder.length - 1 && <button className='nav-btn btn-next' onClick={()=>{this.setTab(tabsOrder[activeTabIdx + 1])}}>
                next
            </button>}
        </div>
    )
  }


  render() {

    return (
      <>
        <h1 style={{textAlign:"center"}}>Automated Correlation</h1>
        {!this.state.running && this.tabsView()}
        {this.state.running && <ProgressBar defaultValue={0} context={CONTEXT} title='Automated Correlation in progress...' />}
      </>
    );
  }
}

export default AutomatedCorrelationComponent;

import React, { Component } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import DialogContentText from '@mui/material/DialogContentText';
import DialogContent from '@mui/material/DialogContent';
// import { AiOutlineClose, AiOutlinePlus, AiTwotoneBulb } from "react-icons/ai";
import { Button } from '@mui/material';
import ToastHelper from '../../../utils/helpers/ToastHelper';

import { EventNames } from '../../../utils/enums/EventNames';
import { getLogs, getWellDeviationProperties, getTracksLogs } from "../../../services/wellService";
// import { MultiWellCorrelationWidget } from '../../Incharts/Original/well/MultiWellCorrelationWidget';
import GlobalWellMetadataInfo from '../../../global/GlobalWellMetadataInfo';
// import InpetuHelper from '../../../utils/helpers/InpetuHelper';
import { getUserConfs, saveUserConfs } from '../../../services/projectService';
import KogCharts from '../../KogCharts/KogCharts';
import { addNewMarkersData, getMarkersData } from '../../../services/stratigraphicService';
import { ProgressBar, setProgress } from '../../ProgressBar/ProgressBar';
import { refreshDataTab } from '../DataTabComponent/DataTabComponent';
import DataManagement from '../../KogCharts/DataManagement';
import isEqual from 'lodash.isequal';

interface newWellMarkerDialog{
  title:string;
  wellName:string;
  depth:number;
}

interface WellViewTabComponentProps {

}

interface WellViewTabComponentState {
  chartData: any,
  plotConfigs:any,
  selectedData:any[],
  tracksDisplay:any,
  showTrackHint:boolean,
  // chartManagement: InpetuHelper,
  geologicalDepth: string,
  wellsWithDeviation: string[],
  kogChartsData:any,
  showNewMarkerDialog:boolean,
  newMarkerDialogParameters:newWellMarkerDialog,
  prevExistentMarkerDialog:boolean,
  loading:boolean,
  trackState:any,
}

const well_depth = [
  {key:"MD", show:"MD"},
  {key:"TVD", show:"TVD"},
  {key:"TVDSS", show:"TVDSS"}
];

const depthsNames = [
  well_depth[0].key,
  well_depth[1].key,
  well_depth[2].key
]

const pageContext = "WELLVIEWCONTEXT";

export default class WellViewTabComponent extends Component<WellViewTabComponentProps, WellViewTabComponentState> {

  markerName:any
  dataManagement:DataManagement
  timerToSend:any
  tracksConf:any[]
  prevParams:any

  constructor(props: any) {
    super(props);
    this.dataManagement = new DataManagement();
    this.markerName = "";
    this.tracksConf = [];
    this.timerToSend = null;
    this.prevParams = []
    
    this.state = {
      chartData: null,
      plotConfigs:{},
      selectedData:[],
      tracksDisplay:{},
      showTrackHint:false,
      // chartManagement: new InpetuHelper(),
      geologicalDepth: "MD",
      wellsWithDeviation: [],
      kogChartsData:{
        data:[]
      },
      showNewMarkerDialog:false,
      newMarkerDialogParameters:{
        title:"",
        wellName:"",
        depth:0
      },
      prevExistentMarkerDialog:false,
      loading:false,
      trackState:{}
    };

    let well_deviations = getWellDeviationProperties()
    well_deviations.then((response)=>{
      let wellDeviationNames:string[] = [];
      if(response.logs_data){
        Object.keys(response.logs_data).map((wellName,well_idx)=>{
          if(response.logs_data[wellName].length>0){
            wellDeviationNames.push(wellName);
          }
        });
      }
      this.setState({wellsWithDeviation:wellDeviationNames});
    })

  }

  trackState = (trackState:any) =>{
    //trackState => {'groupName': {'groupDataName':value} }
    if(!isEqual(trackState,{}) && !isEqual(trackState,this.state.trackState)){
      this.setState({trackState:trackState})
      Object.keys(trackState).map((groupName:string)=>{
        Object.keys(trackState[groupName]).map((groupDataName:string)=>{
          this.tracksConf.push({topic:"WellViewTracksConfig",group:groupName, key:groupDataName, value:trackState[groupName][groupDataName].toString()})
        })
      })
      this.waitToSaveTrackConfs()
    }
  }

  recoverTracksState = () =>{
    getUserConfs().then((userConfs)=>{
      let trackState: {[index: string]:any} = {}
      // This because groups idx need integer, backend returns as string
      if(userConfs.WellViewTracksConfig){
        Object.keys(userConfs.WellViewTracksConfig).map((groupName:string)=>{
          trackState[groupName] = {}
          Object.keys(userConfs.WellViewTracksConfig[groupName]).map((groupDataName:string)=>{
            trackState[groupName][groupDataName] = parseInt(userConfs.WellViewTracksConfig[groupName][groupDataName])
          });
        });
        this.setState({trackState:trackState});
      }
    })
  }

  waitToSaveTrackConfs = () => {
    if(this.timerToSend != null){
      clearTimeout(this.timerToSend);
    }
    this.timerToSend = setTimeout(this.saveTrackConfs,1000);
  }

  saveTrackConfs=()=>{
    saveUserConfs(this.tracksConf).then((response)=>{
    }).catch((error)=>{
    });
  }

  setLoading(loadingState:boolean){
    this.setState({loading:loadingState});
  }

  sendProgress(value:number, message:string){
    setProgress(value,pageContext, message);
    if(value == 100.){
      const customEvent = new CustomEvent("hideProgressBarWellView", { detail: {} });
    document.dispatchEvent(customEvent);
    }
  }

  componentDidMount() {
    this.recoverTracksState()
    document.addEventListener(EventNames.refreshWellTrackPlotEvent, this.changeTrackConfs as EventListener);
    document.addEventListener(EventNames.checkedTreeViewEvent, this.handleCheckedTreeViewEvent as EventListener);
    document.addEventListener(EventNames.uploadMarkerEvent, this.handleUploadMarkersEvent as EventListener);
    document.addEventListener(EventNames.hideNodesEvent, this.handleHideNodesEvent as EventListener);
    document.addEventListener("hideProgressBarWellView", this.hideProgressBar as EventListener);
  }

  componentWillUnmount() {
    document.removeEventListener(EventNames.refreshWellTrackPlotEvent, this.changeTrackConfs as EventListener);
    document.removeEventListener(EventNames.checkedTreeViewEvent, this.handleCheckedTreeViewEvent as EventListener);
    document.removeEventListener(EventNames.uploadMarkerEvent, this.handleUploadMarkersEvent as EventListener);
    document.removeEventListener(EventNames.hideNodesEvent, this.handleHideNodesEvent as EventListener);
    document.removeEventListener("hideProgressBarWellView", this.hideProgressBar as EventListener);
  }

  hideProgressBar = (event: CustomEvent) => {
    if (event.type !== "hideProgressBarWellView"){
      return;
    }
    this.setLoading(false)
  }

  changeTrackConfs = (event: CustomEvent) => {
    if (event.type !== EventNames.refreshWellTrackPlotEvent){
      return;
    }
    var newConfigs = event.detail.settings;
    const displayConf = newConfigs.wellViewState;
    let prevState = this.state.kogChartsData
    var plotConfig:any = {
      tracksLineWidth: parseInt(displayConf.trackLinesThickness),
      tracksWidth: parseInt(displayConf.trackWidth),
      tracksGap: parseInt(displayConf.trackgap),
      tracksHeight: parseInt(displayConf.trackHeight),
      tracksVerticalScale: displayConf.trackShowVerticalScale,
      tracksShowZoom: displayConf.trackShowZoomBar,
      fontSize: parseInt(displayConf.trackFontSize),
    }
    if(newConfigs.wellTracksColor){
      plotConfig.colorsData = newConfigs.wellTracksColor;
    }
    if(displayConf.wellDepth && this.state.geologicalDepth != displayConf.wellDepth){
      this.setGeologicalDepth(displayConf.wellDepth)
    }
    prevState.plotConfig = plotConfig
    this.setState({kogChartsData:prevState})
  }

  refreshPlotData = () => {
    // this.setState({chartData:null},()=>{
    //   var chartManagement = this.state.chartManagement;
    //   this.setState({chartData:chartManagement.getInpetuFormat()},()=>{
    //     this.render();
    //   })
    // })
    if(this.prevParams.length > 0){
      this.dataManagement = new DataManagement();
      this.setLoading(true);
      const prevParams = this.prevParams.map((param:any)=>{
        param.depth_reference = this.state.geologicalDepth;
        return param;
      })
      getTracksLogs(prevParams).then((result: any) => {
        let prevPlotData = this.state.kogChartsData
        this.sendProgress(50.0,"Ploting");
        this.dataManagement.updateGroupsData(result.logs_data)
        setTimeout(() => {
          // let kogChartsData = result.logs_data;
          prevPlotData.data = this.dataManagement.getPlotData(prevParams);
            this.setState({kogChartsData:prevPlotData});
          }, 400)
      }).catch((error) => {
        console.error(error.stack);
      });
    }
  }

  handleCheckedTreeViewEvent = (event: CustomEvent) => {
    if (event.type !== EventNames.checkedTreeViewEvent){
      return;
    }   

    var params:any[] = event.detail.LogParams;
    var tracksDisplay: {[index: string]:any} = {}
    params.forEach((well,wellIdx)=>{
      if(!this.state.tracksDisplay[well.wellname]){
        tracksDisplay[well.wellname] = {wellname:well.wellname,plots:[[]]};
      }
      else{
        tracksDisplay[well.wellname] = this.state.tracksDisplay[well.wellname];
      }
    })
    let kogChart = this.state.kogChartsData;
    kogChart.data = []
    this.setState({selectedData:params,tracksDisplay, kogChartsData: kogChart});
    this.setLoading(true)
    getUserConfs().then((userConfs:any)=>{
      let newParams = JSON.parse(JSON.stringify(params));
      newParams.forEach((element:any, idx:any) => {
        newParams[idx].depth_reference = this.state.geologicalDepth;
        if(this.state.geologicalDepth != userConfs.plotView.plotViewConf.wellDepth && userConfs.plotView.plotViewConf.wellDepth){
          this.setGeologicalDepth(userConfs.plotView.plotViewConf.wellDepth)
        }
        newParams[idx].markers_list = []
        if(this.state.wellsWithDeviation.includes(newParams[idx].wellname)){
          ["TVD", "TVDSS"].forEach((item,itemIdx)=>{
            if(!newParams[idx].logs.includes(item)){
              newParams[idx].logs.push(item)
            }
          })
        }
      });

      this.sendProgress(5.0,"Waiting plot data");

      // let markers = getMarkersData();
      this.prevParams = newParams;
      const missing_data = this.dataManagement.checkMissingData(newParams);
      
      if(missing_data.length==0){
        this.sendProgress(50.0,"Ploting Cached data");
        setTimeout(()=>{
          let kogChartsData = this.state.kogChartsData
          kogChartsData.data = this.dataManagement.getPlotData(newParams);
          this.setState({kogChartsData:kogChartsData});
        },200)
      }
      else{
        getTracksLogs(missing_data).then((result: any) => {
          this.sendProgress(50.0,"Ploting");
          this.dataManagement.updateGroupsData(result.logs_data)
          setTimeout(() => {
            // let kogChartsData = result.logs_data;
            let kogChartsData = this.dataManagement.getPlotData(newParams);
            console.log("Help leon!")
    
              if(userConfs.plotView){
                const displayConf = userConfs.plotView.plotViewConf;
                var plotConfig:any = {
                  tracksLineWidth: parseInt(displayConf.trackLinesThickness),
                  tracksWidth: parseInt(displayConf.trackWidth),
                  tracksGap: parseInt(displayConf.trackgap),
                  tracksVerticalScale: displayConf.trackShowVerticalScale=="true"?true:false,
                  tracksShowZoom: displayConf.trackShowZoomBar=="true"?true:false,
                  fontSize: parseInt(displayConf.trackFontSize),
                }
                if(userConfs.wellTracksColor){
                  plotConfig.colorsData = userConfs.wellTracksColor;
                }
              }
              this.setState({kogChartsData:{data:kogChartsData, plotConfig:plotConfig}});
    
            if(userConfs.plotView){
              var trackConf = {
                wellViewState: userConfs.plotView.plotViewConf,
                wellTracksColor: userConfs.wellTracksColor
              };
              if(!userConfs.wellTracksColor){
                trackConf.wellTracksColor = {};
              }
            }
              
            }, 400)
        }).catch((error) => {
          console.error(error.stack);
        }); 

      }

    }).catch((error) => {
      console.error(error.message);
    });
  };

  findCurveUnit = (versionId: number, logName: string , wellName: string): any => {
    var wellMetadataInfo = GlobalWellMetadataInfo.getDataFromInstance();

    for(var i=0;i< wellMetadataInfo.wellMetadataList.length;i++){
      var item = wellMetadataInfo.wellMetadataList;
      for(var j=0;j<item.length;j++){
        var versionMetadata = item[j].versionMetadata[0];
        
        if(versionMetadata.id === versionId){
          for(var k=0;k<versionMetadata.curveMetatdataInfoList.length;k++){
            var curve = versionMetadata.curveMetatdataInfoList[k];
            if(curve.name === logName){
              return curve.unit;
            }
          }
        }
      }
    }    

    return '';
  }

  groupByWell = (items: any[]): Record<string, any[]> => {
    const group: Record<string, any[]> = {};
  
    items.forEach(item => {
      const wellLabel = item.well.label;
      if (!group[wellLabel]) {
        group[wellLabel] = [];
      }
      group[wellLabel].push(item);
    });
  
    return group;
  }

  handleUploadMarkersEvent = (event: CustomEvent) => {
    if (event.type !== EventNames.uploadMarkerEvent){
      return;
    }  
  };

  handleHideNodesEvent = (event: CustomEvent) => {
    if (event.type !== EventNames.hideNodesEvent){
      return;
    }  
  };  

  getMultiWellCorrelationWidget = (chartData: any) => {
   
  }

  addCurve = (event:any, wellName:any, trackIdx:any) =>{
    var trackOptions:any[] = event.target.childNodes;
    // var chartManagement = this.state.chartManagement;
    var curves:any[] = [];
    trackOptions.forEach((option,optionIdx)=>{
      if(option.selected){
        curves.push(option.value);
      }
    })
  }


  setGeologicalDepth = (value:string) => {
    if(value){
      this.setState({
        geologicalDepth:value
      }, ()=>{
        this.refreshPlotData()
      });
    }
  }

  openAddNewWellMarkerDialog = (params:any) =>{
    const wellName = params.currentGroupName
    const wellDepths = params.clickData[wellName]
    const logsNames = Object.keys(wellDepths);
    const wellDepth = wellDepths[logsNames[0]].currentY.toFixed(2)
    this.setState({
      showNewMarkerDialog:true,
      newMarkerDialogParameters:{
        title:wellName,
        depth:parseFloat(wellDepth),
        wellName:wellName
      }
    })
  }

  checkPreviousWellMarkers = () =>{
    const markersData = getMarkersData().then((response)=>{
      const wellsNames = Object.keys(response.markers_data);
      if(wellsNames.includes(this.state.newMarkerDialogParameters.wellName) && Object.keys(response.markers_data[this.state.newMarkerDialogParameters.wellName]).map((markerName)=>markerName.toUpperCase()).includes(this.markerName.toUpperCase())){
        this.setState({prevExistentMarkerDialog:true})
      }
      else{
        this.sendNewWellMarker()
      }
    })
  }
  
  sendNewWellMarker = () =>{
    let dataset: {[index: string]:any} = {};
    dataset[this.state.newMarkerDialogParameters.wellName] = {}
    dataset[this.state.newMarkerDialogParameters.wellName][this.markerName] = this.state.newMarkerDialogParameters.depth
    addNewMarkersData(dataset).then((response)=>{
      refreshDataTab()
      if(response.message == "New markers was successful added"){
        ToastHelper.success(`Add new Well Marker '${this.markerName}' was successful!`);
      }
      else{
        ToastHelper.error("Error when add new well marker. Please, contact your administrator." , ()=>{
          console.log(response);
        });
      }
    })
  }
  
  render() {

    return (
      <>
        <div>
          <Dialog
            open={this.state.prevExistentMarkerDialog}
            onClose={()=>{ this.setState({prevExistentMarkerDialog:false}) }}
            PaperProps={{
              component:"form",
              onSubmit: (event: React.FormEvent<HTMLFormElement>) =>{
                event.preventDefault();
                this.setState({prevExistentMarkerDialog:false}, ()=>{
                  getMarkersData().then((response)=>{
                    this.markerName = Object.keys(response.markers_data[this.state.newMarkerDialogParameters.wellName]).filter((markerName)=>markerName.toUpperCase() == this.markerName.toUpperCase())[0]
                    this.sendNewWellMarker()
                  })
                })
              }
            }}
          >
            <DialogTitle>Override well marker?</DialogTitle>
            <DialogContent>
              <DialogContentText>{this.markerName} Already exist in {this.state.newMarkerDialogParameters.wellName} this operation will override this data, you sure?</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={()=>{this.setState({prevExistentMarkerDialog:false})}}>No</Button>
              <Button type='submit'>Yes, override it!</Button>
            </DialogActions>
          </Dialog>
          <Dialog 
            open={this.state.showNewMarkerDialog}
            onClose={()=>{ this.setState({showNewMarkerDialog:false}) }}
            PaperProps={{
              component:"form",
              onSubmit: (event: React.FormEvent<HTMLFormElement>) =>{
                event.preventDefault();
                const formData = new FormData(event.currentTarget);
                const formJson = Object.fromEntries((formData as any).entries());
                this.markerName = formJson.markerName;
                this.checkPreviousWellMarkers()
                this.setState({showNewMarkerDialog:false})
              }
            }}
          >
            <DialogTitle>Add new Well Marker</DialogTitle>
            <DialogContent>
              <DialogContentText>Set new marker name to add in {this.state.newMarkerDialogParameters.depth} on {this.state.newMarkerDialogParameters.wellName}</DialogContentText>
              <TextField 
                autoFocus
                required
                id="markerName"
                name="markerName"
                label="Marker name"
                type="text"
                fullWidth
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={()=>{this.setState({showNewMarkerDialog:false})}}>Cancel</Button>
              <Button type='submit'>Save</Button>
            </DialogActions>
          </Dialog>
        </div>
        {/* <div className='row'>
          <div className='col-2'>
            <label>
              Depth unit:
              <select id="wellSet" className='py-1 px-2 ml-2' onChange={(event)=>{this.setGeologicalDepth(event.target.value)}}>
                  {well_depth.map((depth, idx)=>{
                      return <option value={depth.key} selected={depth.key==this.state.geologicalDepth}
                      >{depth.show}</option>
                  })}
              </select>
            </label>
          </div>
        </div> */}
        {this.state.kogChartsData.data.length == 0 && (<h1 className='selectData'>Select Well data in the Data tree</h1>)}
        {
          this.state.loading && (
            <div className='row'>
              <ProgressBar defaultValue={0} context={pageContext} title='Loading wells data...' />
            </div>
          )
        }
        <div>          
          {<KogCharts 
          data={this.state.kogChartsData.data} 
          plotConfig={this.state.kogChartsData.plotConfig}
          trackLeftClickOptions={[
            {displayName:"Add new Marker", blocked:false, callback:(data:any)=>{this.openAddNewWellMarkerDialog(data)}},
            {displayName:"Log10 filter", blocked:true, callback:()=>{console.log("Tchan!")}},
            {displayName:"Clean/Clip Outliers", blocked:true, callback:()=>{console.log("Tchan!")}},
          ]}
          progressStatus={this.sendProgress}
          defaultGroupsIdx={this.state.trackState}
          tracksIdxCallback={this.trackState}
          />}          
        </div>
      </>
    );
      
  }
  
}
//External packages
import React, { Component } from 'react';

//Internal packages
import "./OptionsCorrelationTab.css";
import { CorrelationTabEvents } from '../../../../../utils/enums/CorrelationsTab';
import { getHostInfo } from '../../../../../services/correlationService';
import { number } from 'yup';

interface OptionsCorrelationTabState {
    logsNames: string[],
    logsOptions: any,
    timerToSend:any,
    numberOfCores:number,
    maxNumberOfCores:number,
}

interface OptionsCorrelationTabProps {
    logsNames: string[],
    logsOptions: any
}

let samplingMethods = [
    // {key:"Default Method", show:"Default Method"},
    // {key:"Upscaling", show:"Upscaling"},
    // {key:"Interpolation", show:"Interpolation"}
    {show:"Mean", key:"arithmetic"},
    {show:"Median", key:"Median"},
    {show:"Minimum", key:"min"},
    {show:"Maximum", key:"max"}
]

class OptionsCorrelationsTab extends Component<OptionsCorrelationTabProps, OptionsCorrelationTabState> {

    optionUpdateEvent: string = CorrelationTabEvents.correlationOptionsUpdate;
    previousTabEvent: string = CorrelationTabEvents.previousTabEvent;
    nextTabEvent: string = CorrelationTabEvents.nextTabEvent;

    constructor(props:OptionsCorrelationTabProps){
        super(props);
        let optionsNames = Object.keys(this.props.logsOptions);
        let options = this.props.logsOptions;
        if(optionsNames.length != this.props.logsNames.length){
            this.props.logsNames.forEach((logName:any,idx:any)=>{
                if(!optionsNames.includes(logName)){
                    options[logName] = {logWeights:1.0, norm:0.125, sampling:samplingMethods[0].key};
                }
            });
        }
        this.state = {
            logsNames: props.logsNames,
            logsOptions: options,
            timerToSend:null,
            numberOfCores:2,
            maxNumberOfCores:2
        };
        this.setTimerToSendChanges();
        this.defineHostInfo();
    }

    defineHostInfo = () =>{
        getHostInfo().then((response)=>{
            const cores = response.cpu.n_cores;
            this.setState({numberOfCores:cores, maxNumberOfCores:cores},()=>{
                this.setTimerToSendChanges();
            });
        })
    }

    changeCurveWeights = (value:number, logName:any, callback:any) =>{
        let options = this.state.logsOptions;
        options[logName].logWeights = value;
        this.setState({logsOptions:options},()=>{callback()});
        this.setTimerToSendChanges();
    }

    changeCurveNorms = (value:number, logName:any, callback:any) =>{
        let options = this.state.logsOptions;
        options[logName].norm = value;
        this.setState({logsOptions:options}, ()=>{callback()});
        this.setTimerToSendChanges();
    }

    changeCurveSamplingMethod = (value:string, logName:any) =>{
        let options = this.state.logsOptions;
        options[logName].sampling = value;
        this.setState({logsOptions:options});
        this.setTimerToSendChanges();
    }

    setTimerToSendChanges = () => {
        if(this.state.timerToSend != null){
            clearTimeout(this.state.timerToSend);
          }
          var timer = setTimeout(this.sendConfs,1000);
          this.setState({timerToSend:timer});
    }

    sendConfs = () =>{
        const customEvent = new CustomEvent(this.optionUpdateEvent, { detail: {
            logsOptions:this.state.logsOptions,
            numberOfCores:this.state.numberOfCores,
        } 
        });
        document.dispatchEvent(customEvent);
    }
    
    render(){
        if(this.state.logsNames.length > 0){
            return(
                <>
                    <div className='correlationsOptions'>
                        <div className="numberCoresDiv">
                            <label>Number of cores</label>
                            <input type="number" min={0} max={this.state.maxNumberOfCores} value={this.state.numberOfCores} onChange={(event)=>{ this.setState({numberOfCores:parseInt(event.target.value)}) }} />(Max:{this.state.maxNumberOfCores})
                        </div>                        
                        {this.state.logsNames.map((logName:any, idx:any) => {
                            return (
                                <>
                                    <div className='row mt-3'>
                                        <div className='col-3'><b>{logName}</b></div>
                                        <div className='col-2'>
                                            <div className='fieldset'>
                                                <label style={{display:"grid"}} className='legend'> Log Weights</label>
                                                <input type='text' min={0.0} max={2.0} step={0.01} onChange={(event:any) => {
                                                    event.persist()
                                                    const caretStart = event.target.selectionStart;
                                                    const caretEnd = event.target.selectionEnd;
                                                    let value = event.target.value;
                                                    if(value.trim() == "-"){
                                                        value = 0.005;
                                                    }
                                                    if(parseFloat(value) < 0.){
                                                        value = 0.
                                                    }
                                                    if(Number.isNaN(parseFloat(value))){
                                                        value = 0.
                                                    }
                                                    this.changeCurveWeights(value,logName, ()=>{event.target.setSelectionRange(caretStart, caretEnd);})
                                                    }} 
                                                    value={parseFloat(this.state.logsOptions[logName].logWeights).toFixed(2).toString()} 
                                                />                                                
                                            </div>
                                        </div>
                                        <div className='col-2'>
                                            <div className='fieldset'>
                                                <label style={{display:"grid"}} className='legend'> Norm</label>
                                                <input type='text' 
                                                    onChange={(event:any) => {
                                                        event.persist()
                                                        const caretStart = event.target.selectionStart;
                                                        const caretEnd = event.target.selectionEnd;
                                                        let value = event.target.value;
                                                        if(value.trim() == "-"){
                                                            value = 0.005;
                                                        }
                                                        if(parseFloat(value) > 2.0){
                                                            value = 2.0
                                                        }
                                                        if(parseFloat(value) < 0.005){
                                                            value = 0.005
                                                        }
                                                        if(Number.isNaN(parseFloat(value))){
                                                            value = 0.
                                                        }
                                                        this.changeCurveNorms(value,logName, ()=>{event.target.setSelectionRange(caretStart, caretEnd);})
                                                        
                                                        }
                                                    } 
                                                    value={parseFloat(this.state.logsOptions[logName].norm).toFixed(3).toString()} 
                                                />                                                
                                            </div>
                                        </div>
                                        <div className='col-3'>
                                            <div className='fieldset'>
                                                <label style={{display:"grid"}} className='legend'> Sampling method</label>
                                                <select onChange={(event:any) => {this.changeCurveSamplingMethod(event.target.value,logName)}} >
                                                    {samplingMethods.map((method,samplingIdx)=>{
                                                        if(this.state.logsOptions[logName].sampling == method.key){
                                                            return <option value={method.key} selected>{method.show}</option>
                                                        }
                                                        return <option value={method.key}>{method.show}</option>
                                                    })}
                                                </select>                                                
                                            </div>
                                        </div>                                        
                                    </div>
                                </>
                            )
                        })}
                    </div>

                </>
            )
        }
        else{
            return <>
                Select at least one Properties in <b>Properties tab</b> to display boundaries table                
            </>
        }
    }

}

export default OptionsCorrelationsTab;
//External packages
import React, { Component, useRef } from 'react';
import Slider from '@mui/material/Slider';
import { createTheme } from '@mui/material/styles';
import { grey } from '@mui/material/colors';
import Tooltip from '@mui/material/Tooltip';

// Internal Packages
import "./GeologicalSettingsCorrelationTab.css"
import { CorrelationTabEvents } from '../../../../../utils/enums/CorrelationsTab';
import { FaPlusCircle, FaMinusCircle } from "react-icons/fa";
import isEqual from 'lodash.isequal';

const theme = createTheme({
    palette: {
      primary: {
        light: '#eeeeee',
        main: '#9e9e9e',
        dark: '#424242',
      }
    },
  });

interface GeologicalSettingsCorrelationTabState {
    radiusValue: number; 
    correlationsNumber: number;     
    currentValueInput: number;
    enabledWellDepth: string[];
    correlationOffset:number;
    correlationResolution:number;
    correlationDepth:string;
    timerToSend:any;
    tableRows: any;
    wellsInfo:any;
    wellMarkers:string[];
    wellsSeaLevel: any;
    seaLevelOptionSelected: boolean;    
    // boundaries:any;
}

interface GeologicalSettingsCorrelationTabProps {
    correlationRadius:number;
    correlationOffset:number;
    correlationResolution:number;
    correlationDepth:string;
    wells:string[];
    tableRows: any;
    wellsPairsDistance:any[];
    boundariesDataset:any;
    wellsMarkers:string[];
    wellsProperties:any;
}

const min = 1;
const max = 10000;

const doe_value = (value:number) =>{
    return Math.pow(min, 1 - value) * Math.pow(max, value);
}

const doe_value_inverse = (value:number) =>{
    return (Math.log(value) - Math.log(min)) / (Math.log(max) - Math.log(min));
}

const well_depth = [
    {key:"MD", show:"MD"},
    {key:"TVD", show:"TVD"},
    {key:"TVDSS", show:"TVDSS"}
];

function valuetext(value: number) {
    return `${parseFloat(doe_value(value).toString()).toFixed(2)} Km`;
}

const rangeMarks = [
    {
        value: 0,
        label: '1 Km',
    },
    {
        value: 0.25,
        label: `${doe_value(0.25).toFixed(2)} Km`,
    },
    {
        value: doe_value_inverse(100),
        label: `${doe_value(doe_value_inverse(100)).toFixed(2)} Km`,
    },
    {
        value: 0.75,
        label: `${doe_value(0.75).toFixed(2)} Km`,
    },
    {
        value: 1,
        label: '10000 Km',
    }
]


class GeologicalSettingsCorrelationTab extends Component<GeologicalSettingsCorrelationTabProps, GeologicalSettingsCorrelationTabState> {

    geologicalSettingsUpdateEvent: string = CorrelationTabEvents.geologicalSettingsUpdate;
    previousTabEvent: string = CorrelationTabEvents.previousTabEvent;
    nextTabEvent: string = CorrelationTabEvents.nextTabEvent;

    constructor(props:any){
        super(props);

        let tableRowsData = this.props.tableRows;
        if(tableRowsData.length==0){
            tableRowsData = [{name:"Correlation Start",marker:"", wells:{}}, {name:"Datum", marker:"", wells:{}}, {name:"Correlation Stop",marker:"", wells:{}}]
        }
        this.state = {
            radiusValue: doe_value_inverse(this.props.correlationRadius),
            correlationsNumber: 0,
            currentValueInput:  this.props.correlationRadius,
            enabledWellDepth: ['MD',"TVD","TVDSS"],
            correlationDepth:this.props.correlationDepth,
            correlationResolution:this.props.correlationResolution,
            correlationOffset:this.props.correlationOffset,
            timerToSend:null,
            tableRows:tableRowsData,
            wellsInfo:{},
            // wellMarkers:["mk1","mk2"],
            wellMarkers:[],
            wellsSeaLevel: {},
            seaLevelOptionSelected: false,
            // boundaries:{}
        }

        // this.updateBoundariesTableDataset()

        this.calculateCorrelations();
        this.setTimerToSendChanges();
        this.numberChange = this.numberChange.bind(this)
        // this.getWellMarkers()
    }

    componentDidMount(): void {
        this.calculateCorrelations();
        this.updateBoundariesTable();
    }

    componentDidUpdate(prevProps: Readonly<GeologicalSettingsCorrelationTabProps>, prevState: Readonly<GeologicalSettingsCorrelationTabState>, snapshot?: any): void {
        if(!isEqual(prevProps.wellsPairsDistance, this.props.wellsPairsDistance)){
            this.calculateCorrelations();
        }
        if(!isEqual(prevProps.boundariesDataset, this.props.boundariesDataset)){
            this.updateBoundariesTable();
        }
    }

    updateBoundariesTable = () =>{
        const boundariesData = this.props.boundariesDataset;
        let tableRowsData = this.state.tableRows
        this.props.wells.forEach((wellName:any,idx)=>{
            if(boundariesData[wellName]){
                if(this.props.wells.includes(wellName) && boundariesData[wellName].start[this.state.correlationDepth] != undefined){
                    if(boundariesData[wellName].start[this.state.correlationDepth] != undefined){
                        tableRowsData[0].wells[wellName] = boundariesData[wellName].start[this.state.correlationDepth];
                    }
                    if(boundariesData[wellName].stop[this.state.correlationDepth] != undefined){
                        tableRowsData[tableRowsData.length - 1].wells[wellName] = boundariesData[wellName].stop[this.state.correlationDepth];
                    }
                    for(let rowIdx = 1; rowIdx <= tableRowsData.length - 2; rowIdx++){
                        if(!tableRowsData[rowIdx].wells[wellName] && !boundariesData[wellName].markers[tableRowsData[rowIdx].marker]){
                            tableRowsData[rowIdx].wells[wellName] = 0;
                        }
                        else{
                            if(boundariesData[wellName].markers[tableRowsData[rowIdx].marker]){
                                tableRowsData[rowIdx].wells[wellName] = boundariesData[wellName].markers[tableRowsData[rowIdx].marker][this.state.correlationDepth]
                            }else{
                                tableRowsData[rowIdx].wells[wellName] = "-";
                            }
                        }
                    }
                }
            }
        });
        this.setState({tableRows:tableRowsData},()=>{this.setTimerToSendChanges();})
    }

    updateMarkersDataInBoundariesTable = (rowIdx:number) => {
        const boundariesData = this.props.boundariesDataset;
        let tableRowsData = this.state.tableRows
        this.props.wells.forEach((wellName:any,idx)=>{
            if(boundariesData[wellName]){
                if(this.props.wells.includes(wellName) && boundariesData[wellName].start[this.state.correlationDepth]){
                    if(this.state.tableRows[rowIdx].marker == ""){
                        if(rowIdx == 0){
                            tableRowsData[rowIdx].wells[wellName] =  boundariesData[wellName].start[this.state.correlationDepth]
                        }
                        else if(rowIdx == tableRowsData.length - 1){
                            tableRowsData[rowIdx].wells[wellName] =  boundariesData[wellName].stop[this.state.correlationDepth]
                        }
                        else if(rowIdx == 1 ){
                            tableRowsData[rowIdx].wells[wellName] = 0
                        }
                        else{
                            tableRowsData[rowIdx].wells[wellName] = "-"
                        }
                    }
                    else{
                        if(!boundariesData[wellName].markers[tableRowsData[rowIdx].marker]){
                            tableRowsData[rowIdx].wells[wellName] = "-"
                        }
                        else{
                            tableRowsData[rowIdx].wells[wellName] = boundariesData[wellName].markers[tableRowsData[rowIdx].marker][this.state.correlationDepth]
                        }
                    }
                }
            }
        });
        this.setState({tableRows:tableRowsData})
    }

    setTimerToSendChanges = () => {
        if(this.state.timerToSend != null){
            clearTimeout(this.state.timerToSend);
        }
        var timer = setTimeout(this.sendConfs,1000);
        this.setState({timerToSend:timer});
    }

    sendConfs = () =>{
        const customEvent = new CustomEvent(this.geologicalSettingsUpdateEvent, { detail: {
            correlationRadius:parseFloat(this.state.currentValueInput.toString()),
            correlationDepth: this.state.correlationDepth,
            correlationResolution: this.state.correlationResolution,
            correlationOffset: this.state.correlationOffset,
            boundariesTable: this.state.tableRows,
        } 
        });
        document.dispatchEvent(customEvent);
    }

    doe_value = (value:number) =>{
        return Math.pow(min, 1 - value) * Math.pow(max, value);
    }
    
    doe_value_inverse = (value:number) =>{
        return (Math.log(value) - Math.log(min)) / (Math.log(max) - Math.log(min));
    }

    
    setCurrentValueInput = (value: number, callback?:any) =>{
        this.setState({currentValueInput: value}, ()=>{
            if(callback){
                callback();
            }
        });
        this.setTimerToSendChanges();
    }
    
    setRadiusValue = (value: number) =>{
        this.setState({radiusValue: value}, ()=> this.calculateCorrelations());
        this.setTimerToSendChanges();
    }

    setGeologicalDepth = (value:string) => {
        this.setState({correlationDepth:value},()=>{this.updateBoundariesTable()});
    }

    setCorrelationResolution = (value:number, callback:any) => {
        this.setState({correlationResolution:value},()=>{callback()});
        this.setTimerToSendChanges();
    }

    setCorrelationOffet = (value:number) => {
        this.setState({correlationOffset:value});
        this.setTimerToSendChanges();
    }

    dragInput = (e:any) => {
        this.setRadiusValue(e.target.value);
        this.setCurrentValueInput(doe_value(e.target.value));
    };

    numberChange = (e:any,callback:any) => {
        this.setCurrentValueInput(e.target.value, callback);
        this.setRadiusValue(doe_value_inverse(parseFloat(e.target.value)));
    };

    calculateCorrelations = () => {
        let correlation_distance = this.props.wellsPairsDistance.filter((distance:any)=> this.props.wells.includes(distance.well_pair[0]) && this.props.wells.includes(distance.well_pair[1]) && distance.distance <= parseFloat(this.state.currentValueInput.toString()) * 1000)
        this.setState({correlationsNumber:correlation_distance.length})
    }


    setMarker = (markerName:string, rowIdx:number) =>{
        let rows = this.state.tableRows;
        rows[rowIdx].marker = markerName;
        this.setState({tableRows:rows}, ()=>{
            this.updateMarkersDataInBoundariesTable(rowIdx)
        })
        this.setTimerToSendChanges();
    }

    setData = (value:number, rowIdx:number, wellName:string, callback?:any) =>{
        let rows = this.state.tableRows;
        rows[rowIdx].wells[wellName] = value;
        this.setState({tableRows:rows}, ()=>{
            if(callback){
                callback();
            }
        })
        this.setTimerToSendChanges();
    }

    addRow = (rowIdx:number) => {
        let rows = this.state.tableRows;
        let rowsWells = Object.keys(rows[0].wells);
        let row = {name:"Marker Constraints", marker:"", wells:{}}
        // Comprehension in JS!
        row.wells = Object.fromEntries(rowsWells.map( well => [well,0]));
        rows.splice(rowIdx + 1, 0, row);
        this.setState({tableRows:rows},()=>{
            this.setTimerToSendChanges();
        });
    }

    removeRow = (rowIdx:number) => {
        let rows = this.state.tableRows;
        rows.splice(rowIdx, 1);
        this.setState({tableRows:rows});
        this.setTimerToSendChanges();
    }

    wellSetHandler = (value:string) => {  
        this.setGeologicalDepth(value)
        // if(this.state.seaLevelOptionSelected){
        //     this.changeSeaLevel("sea_level", 1, value);
        // }        
        this.setTimerToSendChanges();
    }

    selectMarkerHandler = (value:any, rowIdx: number)=>{        
        this.setMarker(value, rowIdx);
        // this.changeSeaLevel(value, rowIdx);
        // value == "sea_level" ? this.setState({seaLevelOptionSelected: true}) : this.setState({seaLevelOptionSelected: false});        
        this.setTimerToSendChanges();
    }

    changeSeaLevel = (value:any, rowIdx: number, correlationDepth:string = this.state.correlationDepth)=>{        
        let rows = this.state.tableRows;
        rows[rowIdx].marker = value;
        if(rowIdx == 1 && value == "sea_level"){
            Object.keys(rows[rowIdx].wells).forEach((well)=>{
                rows[rowIdx].wells[well] = this.state.wellsSeaLevel[well][correlationDepth];
            })        
        }else if(rowIdx == 1 && value != "sea_level"){
            Object.keys(rows[rowIdx].wells).forEach((well)=>{
                rows[rowIdx].wells[well] = 0;
            }) 
        }
        this.setState({tableRows: rows}, ()=>{this.setTimerToSendChanges()}) 
    }

    renderBoundariesRows = () =>{
        return(
            <>
                {
                    this.state.tableRows.map((row:any,rowIdx:any)=>{                       
                        return(
                            <tr>
                                <td>
                                    { (rowIdx != 0 && rowIdx != this.state.tableRows.length-1 && rowIdx != 1) && <FaMinusCircle className='removeRow' style={{marginLeft:"-9%"}} onClick={()=>{this.removeRow(rowIdx)}} />}
                                    <span>{row.name}</span>
                                    { (rowIdx != 0 && rowIdx != this.state.tableRows.length-1) && <span className="tooltip-wrapper">
                                        <FaPlusCircle onClick={() => { this.addRow(rowIdx) }} />
                                        <span className="tooltip-text">Add marker constraints!</span>
                                    </span>}
                                </td>
                                <td>
                                    <select onChange={(event)=>{this.selectMarkerHandler(event.target.value,rowIdx)}}>
                                        {(rowIdx == 0) && <option value="">Well Start</option>}
                                        {(this.state.tableRows.length -1 == rowIdx) && <option value="">Well Stop</option>}
                                        {(rowIdx != 0 && rowIdx != 1 && this.state.tableRows.length -1 != rowIdx) && <option value="" selected>Select</option>}      
                                        {(rowIdx == 1) && <option value="">Sea Level</option>}
                                        {this.props.wellsMarkers.map((marker,markerIdx)=>{
                                            return <option value={marker} selected={row.marker == marker}>{marker}</option>
                                        })}
                                    </select>
                                </td>
                                {this.props.wells.map((wellName,idx)=>{
                                    return (
                                        <td 
                                            contentEditable={true} 
                                            onInput={(event:any)=>{
                                                var setpos = document.createRange();
                                                var set = window.getSelection();
                                                const position = event.target.childNodes[0]
                                                let offset = event.target.childNodes[0].length;
                                                if(set){
                                                    offset = set.getRangeAt(0).endOffset;

                                                }
                                                
                                                
                                                this.setData(parseFloat(event.target.innerText),rowIdx,wellName, ()=>{
                                                    if(set){
                                                        setpos.setStart(position, offset);
                                                        setpos.collapse(true);
                                                        set.removeAllRanges();
                                                        set.addRange(setpos);
                                                        event.target.focus()
                                                    }
                                                }) 
                                            }}
                                            >
                                            {row.wells[wellName]}
                                        </td>
                                    )
                                })}
                            </tr>
                        )                        
                    })
                }
            </>
        )
    }

    renderBoundariesTable = () =>{
        const invalid_well:React.CSSProperties = {
            backgroundColor: "var(--primary-color)",
            cursor:"pointer",
        }
        if(this.props.wells.length > 0){
            return(
                <>
                    <table className='table-bordered boundariesTable'>
                        <thead>
                            <tr>
                                <th></th>
                                <th>Select a marker</th>
                                {this.props.wells.map((wellName,idx)=>{
                                    return (
                                        <th style={this.props.wellsProperties[wellName].length == 0?invalid_well:{}}>
                                            {this.props.wellsProperties[wellName].length == 0 && <Tooltip placement="top" title="This well cannot be correlated because it has no properties selected! Go back and selet them."><span>{wellName}</span></Tooltip>}
                                            {this.props.wellsProperties[wellName].length > 0 && <span>{wellName}</span>}
                                        </th>
                                    )
                                })}
                            </tr>
                        </thead>
                        <tbody>
                            {this.renderBoundariesRows()} 
                        </tbody>
                    </table>
                </>
            );
        }
        return <>Select at least one well in <b>Wells tab</b> to display boundaries table</>
    }
    
    render(): React.ReactNode {
        return(
            <>
                <div className="geologicalSettingsOptions">
                    <div className="left">                        
                        <div>
                            <label className='labelRadius'>Radius of Correlation:</label>
                            <div className='radiusStyle'>
                                <div className="divSlider">
                                    <Slider
                                        aria-label="Distance"
                                        defaultValue={this.state.radiusValue}
                                        valueLabelDisplay="auto"
                                        step={0.00001}
                                        min={0}
                                        max={1}
                                        value={this.state.radiusValue}
                                        valueLabelFormat={valuetext}
                                        marks={rangeMarks}
                                        onChange={(event) => {
                                            this.dragInput(event);
                                        }}
                                        sx={{color: "var(--orange)"}}                                 
                                    />
                                </div>
                                <div className='radiusDiv'>
                                    <input
                                        type="text"
                                        value={parseFloat(this.state.currentValueInput.toString()).toFixed(2).toString() + " km"}
                                        onChange={(event)=>{
                                            event.persist()
                                            const caretStart = event.target.selectionStart;
                                            const caretEnd = event.target.selectionEnd;
                                            this.numberChange(event, ()=>{event.target.setSelectionRange(caretStart, caretEnd);});
                                            
                                        }}
                                        max={max}
                                        step={0.01}
                                        min={min}
                                    />                                    
                                </div>
                            </div>                            
                        </div>
                        <div>
                            <label>Number of correlations:</label>
                            <span>{this.state.correlationsNumber}</span>                                
                        </div>
                    </div>
                    <div className="right">
                        <div>
                            <label>Correlation in:</label>                                    
                            <select id="correlationOptionsDepth" onChange={(event)=>{this.wellSetHandler(event.target.value)}}>
                                {well_depth.map((depth, idx)=>{
                                    return <option value={depth.key} selected={idx==0} disabled={!this.state.enabledWellDepth.includes(depth.key)}>{depth.show}</option>
                                })}                                    
                            </select>  
                        </div>
                        <div>
                            <label>Correlation resolution:</label>
                            <input                                
                                type="text"                                
                                placeholder='Add the measure'
                                value={this.state.correlationResolution.toFixed(4).toString()}
                                onChange={(event)=>{
                                    event.persist()
                                    const caretStart = event.target.selectionStart;
                                    const caretEnd = event.target.selectionEnd;
                                    let value:any = event.target.value;
                                    if(parseFloat(value) < 0.){
                                        value = 0.
                                    }
                                    if(Number.isNaN(parseFloat(value))){
                                        value = 0.
                                    }
                                    this.setCorrelationResolution(parseFloat(value), ()=>{event.target.setSelectionRange(caretStart, caretEnd);})}
                                }
                            /><strong>m</strong> 
                                
                        </div>
                        <div>
                            <label>Maximum correlation offset:</label>
                            <input                                
                                type="text"                                
                                placeholder='Add the measure'
                                value={this.state.correlationOffset.toFixed(0).toString()}
                                onChange={(event)=>{
                                    let value:any = event.target.value;
                                    if(parseFloat(value) < 0.){
                                        value = 0.
                                    }
                                    if(Number.isNaN(parseFloat(value))){
                                        value = 0.
                                    }
                                    this.setCorrelationOffet(parseFloat(value))
                                }}
                            /> <strong>m</strong>                             
                        </div>                        
                    </div>
                </div>                
                <div>
                    <div className='boundariesTableDiv styled-scrollbars'>
                        {this.renderBoundariesTable()}
                    </div>                  
                </div>
            </>
        )
    }
}

export default GeologicalSettingsCorrelationTab;
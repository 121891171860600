import React, { Component } from 'react';
import SelectItemsListComponent from '../../SelectItemsList/SelectItemsList';
import { wellMetadata } from '../../../services/wellService';
import { downloadMarkers, getMarkersData } from '../../../services/stratigraphicService';
import ParserParameters, { DoubleQuotation, Hash, m9999_99, MD, Meter, OneSpace } from '../../ParserFields/ParserValues';
import ParserFieldsComponent from '../../ParserFields/ParserFields';
import FixWellInfosTable from '../../FixWellInfosTable/FixWellInfosTable';
import { EventNames } from '../../../utils/enums/EventNames';
import ToastHelper from '../../../utils/helpers/ToastHelper';

interface WellMarkersExportComponentState {    
    activeTab:string;
    wellsMetadata: any[];
    wellsNames: string[];
    wellsProperties: string[];
    wellsMarkers: string[];
    delimiter: string,
    qualifier: string,
    comment: string,
    depthUnit: string,
    depthType: string,
    noValue: string,
    selectedWells: string[],
    selectedMarkers: string[],
}

const CONTEXT = 'wellmarkers';

enum TabNames{
    selectWells = "Select Wells",
    selectMarkers = "Select Markers",
    selectFormat = "Select Format"
}

const tabOrder: string[] =[
    TabNames.selectWells,
    TabNames.selectMarkers,
    TabNames.selectFormat,
]

export default class WellMarkersExportComponent extends Component<{}, WellMarkersExportComponentState> {
    SelectItemsListEvent: string = EventNames.SelectItemsListEvent;  

    constructor(props: any) {
        super(props);        
        this.state = {
            activeTab: TabNames.selectWells,
            wellsMetadata: [],
            wellsNames: [],
            wellsProperties: [],
            wellsMarkers: [],
            delimiter:OneSpace.value,
            qualifier:DoubleQuotation.value,
            comment:Hash.value,
            depthUnit:Meter.value,
            depthType:MD.value,
            noValue:m9999_99.value,
            selectedWells: [],
            selectedMarkers: [],
        };
                
    }

    componentDidMount(){
        this.loadWellsData();
        document.addEventListener(this.SelectItemsListEvent, this.receiveSelectedItems);
    }

    componentWillUnmount(){
        document.removeEventListener(this.SelectItemsListEvent, this.receiveSelectedItems);
    }

    receiveSelectedItems = (event:any) => {
        if (event.type !== this.SelectItemsListEvent && (event.detail.context != "Wells" || event.detail.context != "Markers")){
          return;
        }
    
        const dataset = event.detail;
        
        if(event.detail.context == "Wells"){
            this.setState({selectedWells: dataset.items}, ()=>{this.loadMarkers();}) 
        }

        if(event.detail.context == "Markers"){
            this.setState({selectedMarkers: dataset.items}) 
        }               
    }
   
    loadWellsData = ()=>{
        wellMetadata().then((result)=>{          
          this.setState({wellsMetadata:result}, ()=>{
            this.loadWellsNames();
            //this.loadMarkers();
            //this.loadWellsProperties();            
        });
        }).catch((error) => {
          console.error(error);
        });
    }

    setTab = (tabName:string) => {
        this.setState({activeTab:tabName});
    }

    tabHeader = () =>{
        return (
            <div className="process-nav">
                {tabOrder.map((currentTab,idx)=>{
                    return (
                        <div className={`col k-tab ${currentTab==this.state.activeTab?"activeTab":""}`} onClick={()=>{this.setTab(currentTab)}}>
                            {currentTab}
                        </div>
                    )
                })}
            </div>
        )
    }

    export = ()=>{
        let parameters: any = {
            comment: this.state.comment, 
            delimiter: this.state.delimiter,
            depth_unit: this.state.depthUnit,
            distance_unit: this.state.depthUnit,
            header: false,
            qualifier: this.state.qualifier
        }
        
        let new_well_names:any[] = [];
       
        this.state.selectedWells.forEach((old_wellName:string,wellIdx)=>{
            new_well_names.push({old:old_wellName,new:old_wellName});
        });

        let markers_names: string[] = []

        this.state.selectedMarkers.forEach((marker:any)=>{
            markers_names.push(marker.toString())
        })

        let params: any = {filename: "teste.csv", wells_names: this.state.selectedWells, markers_names: markers_names, new_wells_names: new_well_names, parameters: parameters}        
        downloadMarkers(params).catch(error=>{
            ToastHelper.error(`Error: ${error.message}`)
        });
    }

    tabsNavButton = () =>{
        let activeTabIdx = tabOrder.indexOf(this.state.activeTab);
        return (
            <div className="tabsNavButton">
                { tabOrder.indexOf(this.state.activeTab) != 0 && <button className='nav-btn' onClick={()=>{this.setTab(tabOrder[activeTabIdx - 1])}}>
                    back
                </button>}
                { tabOrder.indexOf(this.state.activeTab) < tabOrder.length - 1 && <button className='nav-btn btn-next' onClick={()=>{this.setTab(tabOrder[activeTabIdx + 1])}}>
                    next
                </button>}

                { tabOrder.indexOf(this.state.activeTab) == tabOrder.length - 1 && <button className='nav-btn btn-next' onClick={()=>{this.export()}}>
                    export
                </button>}
            </div>
        )
    }

    loadWellsNames = ()=>{
        const wellsNames:string[] = Object.keys(this.state.wellsMetadata)

        this.setState({wellsNames:wellsNames})        
    }

    loadWellsProperties = ()=>{
        let wellsPropertiesList: string[] = []
        Object.keys(this.state.wellsMetadata)?.map((well:any) =>{
            Object.keys(this.state.wellsMetadata[well].curves?.versions[0].curves_info).map((prop:any)=>{
                if(!wellsPropertiesList.includes(prop)){
                    wellsPropertiesList.push(prop)
                }
            })
        })

        this.setState({wellsProperties:wellsPropertiesList})           
    }

    loadMarkers = ()=>{
        let markersList:string[] = []
        getMarkersData().then((result)=>{            
            this.state.selectedWells.forEach((well:any)=>{
                const wellMarkers = result.markers_data[well]
                Object.keys(wellMarkers).forEach((marker:any) => {
                    if(!markersList.includes(marker)){
                        markersList.push(marker)
                    }
                });
            })                 
            this.setState({wellsMarkers:markersList.sort()});
        }).catch((error) => {
            console.error(error);
        });
    }
  
    tabsView = () =>{
        return (
          <section id="tabs">
            <div>          
              {this.tabHeader()}
            </div> 
            <div className="tab-content">
                <div
                    className={`tab-pane fade show ${this.state.activeTab === TabNames.selectWells ? 'active' : ''}`}          
                    role="tabpanel"
                    style={{textAlign:"center"}}    
                >
                    <SelectItemsListComponent listItems={this.state.wellsNames} title='Wells' context='wells'/>
                </div>
                <div
                    className={`tab-pane fade show ${this.state.activeTab === TabNames.selectMarkers ? 'active' : ''}`}          
                    role="tabpanel"
                    style={{textAlign:"center"}}    
                >
                    <SelectItemsListComponent listItems={this.state.wellsMarkers} title='Markers' context='markers'/>
                </div> 
                <div
                    className={`tab-pane fade show ${this.state.activeTab === TabNames.selectFormat ? 'active' : ''}`}          
                    role="tabpanel"          
                >
                <ParserFieldsComponent
                    context={'exportMarkers'}
                    delimiter={this.state.delimiter}
                    qualifier={this.state.qualifier}
                    comment={this.state.comment}
                    startLine={0}
                    depthUnit={this.state.depthUnit}
                    depthType={this.state.depthType}
                    noValue={this.state.noValue}
                    options={{
                        delimiter: new ParserParameters().delimiters,
                        qualifier:new ParserParameters().qualifiers,
                        comment:new ParserParameters().comments,
                        depthUnit: new ParserParameters().DistanceUnit,
                        depthType:new ParserParameters().DeptType,
                        noValue: new ParserParameters().NoValues,
                    }}
                    showFields={{startLine: false, noValue: false}}
                />                
              </div>
            </div>
            {this.tabsNavButton()}
          </section>
        )
    }
      
    
    render(): React.ReactNode {
        return (
            <>
                <h1>Export Well Markers</h1>
                {this.tabsView()}
            </>
        )
    }
}